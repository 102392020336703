<template>
  <section>
    <InvoiceModal :trip="invoiceModalDetails" :symbol="currencySymbol" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus :title="pageTitle" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            title="Total Trips"
            :value="`${indexMetaData.summary.total}`"
            variant="yellow"
          />

          <SummaryCard
            title="On Trip"
            :value="` ${indexMetaData.summary.on_trip}`"
            variant="green"
          />

          <SummaryCard
            title="Trips Completed"
            :value="` ${indexMetaData.summary.completed}`"
            variant="blue"
          />

          <SummaryCard
            title="Trips Cancelled"
            :value="` ${indexMetaData.summary.cancelled}`"
            variant="red"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('trips')"
        class="py-5 font-bold text-center text-gray-600"
      >
        You don't have permission to see data. Please Ask your admin to give you
        permission for this screen.
      </div>

      <div v-else>
        <FSTable
          :qso="qso"
          :fst-id="fstId"
          :headers="getTableHeaders"
          :endpoint="endpoint"
          :currency-enabled="true"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="getFilteredItems"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow
                  :key="itemIndex"
                  :className="{
                    'fst-row': true,
                    'host-child-trip-row': item.child_trips.length > 0,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div class="flex items-center">
                      <router-link
                        :to="{ name: 'ViewTrip', params: { id: item.id } }"
                        :class="`text-blue-600`"
                        target="_blank"
                      >
                        {{ item.id.substr(item.id.length - 5) }}
                      </router-link>
                      <span
                        v-if="item.child_trips.length > 0"
                        class="bg-blue-600 child-trip-index-badge"
                        >G</span
                      >
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="item.user.full_name"
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.user.id },
                    }"
                    target="_blank"
                  />

                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.bike.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.bike.qr_code }}
                    </router-link>

                    <i
                      v-if="item.is_vehicle_locked === true"
                      class="fas fa-lock fa-xs mr-2 text-gray-500 ml-1"
                    ></i>
                    <i
                      v-if="item.is_vehicle_locked === false"
                      class="fas fa-unlock fa-xs mr-2 text-blue-600 ml-1"
                    ></i>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.pick_up_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem :text="item.pick_up_location" />

                  <FSTableRowItem :text="item.drop_off_location" />

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.drop_off_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem>
                    <span
                      :class="
                        item.status === 'O' ? `text-oGreen` : `text-oDark`
                      "
                    >
                      {{ getHumanReadableDuration(item.riding_time) }}
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item" as-currency />
                  <FSTableRowItem>
                    <template v-if="getFlags(item).length > 0">
                      <div class="flex flex-wrap items-center">
                        <template v-for="(flag, flagIndex) in getFlags(item)">
                          <trip-flag-icon
                            v-tooltip.bottom="getFlagAlias(flag, item)"
                            size="sm"
                            class="mr-1"
                            :name="flag"
                            :key="`trip-${itemIndex}-flag-${flagIndex}`"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      No Flags
                    </template>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <XStatus
                      v-tooltip="
                        getTooltipText(
                          item.status,
                          item.status_forced,
                          item.status_forced_by_system
                        )
                      "
                      :text="
                        getUserTripStatus(
                          item.status,
                          item.status_forced,
                          item.status_forced_by_system
                        )
                      "
                      :variant="
                        getUserTripStatusBadge(
                          item.status,
                          item.status_forced,
                          item.status_forced_by_system
                        )
                      "
                      :profile="`trip`"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-eye-icon
                        v-tooltip.bottom="'View Invoice'"
                        @click="onInvoiceModalReq(item.id, false)"
                      />
                      <template v-if="$acl.canUpdate('trips')">
                        <t-dropdown
                          v-if="
                            item.status === 'U' ||
                              item.status === 'O' ||
                              item.status === 'R'
                          "
                          variant="smActions"
                        >
                          <template
                            #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                          >
                            <button
                              class="focus:outline-none"
                              aria-label="More Actions"
                              aria-haspopup="true"
                              @mousedown="mousedownHandler"
                              @focus="focusHandler"
                              @blur="blurHandler"
                              @keydown="keydownHandler"
                            >
                              <svg
                                class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                ></path>
                              </svg>
                            </button>
                          </template>

                          <template #default="{ hide }">
                            <div
                              @click="hide()"
                              class="py-1 mt-px bg-white rounded-md shadow-md"
                            >
                              <t-dropdown-item
                                @click="
                                  onTripAction({
                                    tripId: item.id,
                                    action: 'open',
                                  })
                                "
                              >
                                Force Complete
                              </t-dropdown-item>
                              <t-dropdown-item
                                @click="
                                  onTripAction({
                                    tripId: item.id,
                                    action: 'cancel',
                                  })
                                "
                                v-if="item.status === 'R'"
                              >
                                Force Cancel
                              </t-dropdown-item>
                            </div>
                          </template>
                        </t-dropdown>
                      </template>
                    </div>
                  </FSTableRowItem>
                </FSTableRow>

                <FSTableRow
                  v-for="(childItem, childItemIndex) in item.child_trips"
                  :key="`child-${itemIndex}-${childItemIndex}`"
                  :className="`fst-row child-trip-row`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <router-link
                      :to="{ name: 'ViewTrip', params: { id: item.id } }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ childItem.id.substr(childItem.id.length - 5) }}
                    </router-link>
                    <span class="bg-gray-400 child-trip-index-badge">{{
                      childItemIndex + 1
                    }}</span>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="childItem.user.full_name"
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: childItem.user.id },
                    }"
                  />

                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: childItem.bike.id },
                      }"
                      target="_blank"
                      :class="`text-blue-600`"
                    >
                      {{ childItem.bike.qr_code }}
                    </router-link>

                    <i
                      v-if="childItem.is_vehicle_locked === true"
                      class="fas fa-lock fa-xs mr-2 text-gray-500 ml-1"
                    ></i>
                    <i
                      v-if="childItem.is_vehicle_locked === false"
                      class="fas fa-unlock fa-xs mr-2 text-blue-600 ml-1"
                    ></i>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        childItem.pick_up_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem :text="childItem.pick_up_location" />

                  <FSTableRowItem :text="childItem.drop_off_location" />

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        childItem.drop_off_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem
                    :text="getHumanReadableDuration(childItem.riding_time)"
                  />

                  <FSTableRowItem :text="childItem.fake_prop" />
                  <FSTableRowItem>
                    <template v-if="getFlags(childItem).length > 0">
                      <div class="flex flex-wrap items-center">
                        <template
                          v-for="(flag, flagIndex) in getFlags(childItem)"
                        >
                          <trip-flag-icon
                            v-tooltip.bottom="getFlagAlias(flag, childItem)"
                            size="sm"
                            class="mr-1"
                            :name="flag"
                            :key="`trip-${childItemIndex}-flag-${flagIndex}`"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      No Flags
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <XStatus
                      :text="
                        getUserTripStatus(
                          childItem.status,
                          childItem.status_forced,
                          childItem.status_forced_by_system
                        )
                      "
                      :variant="
                        getUserTripStatusBadge(
                          childItem.status,
                          childItem.status_forced,
                          childItem.status_forced_by_system
                        )
                      "
                      :profile="`trip`"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div
                      v-if="$acl.canUpdate('trips')"
                      class="flex items-center"
                    >
                      <t-dropdown
                        v-if="
                          childItem.status === 'U' ||
                            childItem.status === 'O' ||
                            childItem.status === 'R'
                        "
                        variant="smActions"
                      >
                        <template
                          #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                        >
                          <button
                            class="focus:outline-none"
                            aria-label="More Actions"
                            aria-haspopup="true"
                            @mousedown="mousedownHandler"
                            @focus="focusHandler"
                            @blur="blurHandler"
                            @keydown="keydownHandler"
                          >
                            <svg
                              class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              ></path>
                            </svg>
                          </button>
                        </template>

                        <template #default="{ hide }">
                          <div
                            @click="hide()"
                            class="py-1 mt-px bg-white rounded-md shadow-md"
                          >
                            <t-dropdown-item
                              @click="
                                onTripAction({
                                  tripId: childItem.id,
                                  action: 'open',
                                })
                              "
                            >
                              Force Complete
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onTripAction({
                                  tripId: childItem.id,
                                  action: 'cancel',
                                })
                              "
                              v-if="childItem.status === 'R'"
                            >
                              Force Cancel
                            </t-dropdown-item>
                          </div>
                        </template>
                      </t-dropdown>
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  :className="{
                    'fst-row': true,
                    'host-child-trip-row': item.child_trips.length > 0,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="flex items-center">
                      <router-link
                        :to="{ name: 'ViewTrip', params: { id: item.id } }"
                        :class="`text-blue-600`"
                        target="_blank"
                      >
                        {{ item.id.substr(item.id.length - 5) }}
                      </router-link>
                      <span
                        v-if="item.child_trips.length > 0"
                        class="bg-blue-600 child-trip-index-badge"
                        >G</span
                      >
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.bike.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.bike.qr_code }}
                    </router-link>

                    <i
                      v-if="item.is_vehicle_locked === true"
                      class="fas fa-lock fa-xs mr-2 text-gray-500 ml-1"
                    ></i>
                    <i
                      v-if="item.is_vehicle_locked === false"
                      class="fas fa-unlock fa-xs mr-2 text-blue-600 ml-1"
                    ></i>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item" as-currency />
                </FSTableRow>

                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Rider</div>
                      <div class="col-span-5 right-text">
                        <router-link
                          :to="{
                            name: 'ViewRiderUserProfile',
                            params: { id: item.user.id },
                          }"
                          :class="`text-blue-600`"
                          target="_blank"
                        >
                          {{ item.user.full_name }}
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Pickup Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.pick_up_time,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Pickup Location</div>
                      <div class="col-span-5 right-text">
                        {{ item.pick_up_location || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Drop off Location</div>
                      <div class="col-span-5 right-text">
                        {{ item.drop_off_location || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Drop off Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.drop_off_time,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>

                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Duration</div>
                      <div class="col-span-5 right-text">
                        <span
                          :class="
                            item.status === 'O' ? `text-oGreen` : `text-oDark`
                          "
                        >
                          {{ getHumanReadableDuration(item.riding_time) }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Flags</div>
                      <div class="col-span-5 right-text">
                        <template v-if="getFlags(item).length > 0">
                          <div class="flex flex-wrap items-center">
                            <template
                              v-for="(flag, flagIndex) in getFlags(item)"
                            >
                              <trip-flag-icon
                                v-tooltip.bottom="getFlagAlias(flag, item)"
                                size="sm"
                                class="mr-1"
                                :name="flag"
                                :key="`trip-${itemIndex}-flag-${flagIndex}`"
                              />
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          No Flags
                        </template>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Status</div>
                      <div class="col-span-5 right-text">
                        <XStatus
                          v-tooltip="
                            getTooltipText(
                              item.status,
                              item.status_forced,
                              item.status_forced_by_system
                            )
                          "
                          :text="
                            getUserTripStatus(
                              item.status,
                              item.status_forced,
                              item.status_forced_by_system
                            )
                          "
                          :variant="
                            getUserTripStatusBadge(
                              item.status,
                              item.status_forced,
                              item.status_forced_by_system
                            )
                          "
                          :profile="`trip`"
                        />
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Actions</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <oto-eye-icon
                            v-tooltip.bottom="'View Invoice'"
                            @click="onInvoiceModalReq(item.id, false)"
                          />
                          <template v-if="$acl.canUpdate('trips')">
                            <t-dropdown
                              v-if="
                                item.status === 'U' ||
                                  item.status === 'O' ||
                                  item.status === 'R'
                              "
                              variant="smActions"
                            >
                              <template
                                #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                              >
                                <button
                                  class="focus:outline-none"
                                  aria-label="More Actions"
                                  aria-haspopup="true"
                                  @mousedown="mousedownHandler"
                                  @focus="focusHandler"
                                  @blur="blurHandler"
                                  @keydown="keydownHandler"
                                >
                                  <svg
                                    class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    ></path>
                                  </svg>
                                </button>
                              </template>

                              <template #default="{ hide }">
                                <div
                                  @click="hide()"
                                  class="py-1 mt-px bg-white rounded-md shadow-md"
                                >
                                  <t-dropdown-item
                                    @click="
                                      onTripAction({
                                        tripId: item.id,
                                        action: 'open',
                                      })
                                    "
                                  >
                                    Force Complete
                                  </t-dropdown-item>
                                  <t-dropdown-item
                                    @click="
                                      onTripAction({
                                        tripId: item.id,
                                        action: 'cancel',
                                      })
                                    "
                                    v-if="item.status === 'R'"
                                  >
                                    Force Cancel
                                  </t-dropdown-item>
                                </div>
                              </template>
                            </t-dropdown>
                          </template>
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
                <template
                  v-for="(childItem, childItemIndex) in item.child_trips"
                >
                  <FSTableRow
                    :key="`child-${itemIndex}-${childItemIndex}`"
                    :className="`fst-row child-trip-row`"
                    text-fallback-always
                  >
                    <FSTableRowItem>
                      <div
                        class="col-span-1 focus:text-gray-400"
                        @click="toggle(`${itemIndex}${childItemIndex}`)"
                      >
                        <i
                          class="fas fa-minus-circle"
                          style="color:#d90a20;"
                          v-if="
                            opened.includes(`${itemIndex}${childItemIndex}`)
                          "
                        ></i>
                        <i class="fas fa-plus-circle" v-else></i>
                      </div>
                    </FSTableRowItem>
                    <FSTableRowItem>
                      <router-link
                        :to="{ name: 'ViewTrip', params: { id: item.id } }"
                        :class="`text-blue-600`"
                        target="_blank"
                      >
                        {{ childItem.id.substr(childItem.id.length - 5) }}
                      </router-link>
                      <span class="bg-gray-400 child-trip-index-badge">{{
                        childItemIndex + 1
                      }}</span>
                    </FSTableRowItem>

                    <FSTableRowItem>
                      <router-link
                        :to="{
                          name: 'ViewVehicleProfile',
                          params: { id: childItem.bike.id },
                        }"
                        target="_blank"
                        :class="`text-blue-600`"
                      >
                        {{ childItem.bike.qr_code }}
                      </router-link>

                      <i
                        v-if="childItem.is_vehicle_locked === true"
                        class="fas fa-lock fa-xs mr-2 text-gray-500 ml-1"
                      ></i>
                      <i
                        v-if="childItem.is_vehicle_locked === false"
                        class="fas fa-unlock fa-xs mr-2 text-blue-600 ml-1"
                      ></i>
                    </FSTableRowItem>

                    <FSTableRowItem :text="childItem.fake_prop" />
                  </FSTableRow>
                  <FSTableRow
                    v-if="opened.includes(`${itemIndex}${childItemIndex}`)"
                    :key="childItemIndex"
                  >
                    <td colspan="10">
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Rider</div>
                        <div class="col-span-5 right-text">
                          <router-link
                            :to="{
                              name: 'ViewRiderUserProfile',
                              params: { id: childItem.user.id },
                            }"
                            :class="`text-blue-600`"
                            target="_blank"
                          >
                            {{ childItem.user.full_name }}
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Pickup Time</div>
                        <div class="col-span-5 right-text">
                          {{
                            getFormattedDateTime(
                              childItem.pick_up_time,
                              'D MMM, YY hh:mm:ss a'
                            ) || `--`
                          }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Pickup Location</div>
                        <div class="col-span-5 right-text">
                          {{ childItem.pick_up_location || `--` }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          Drop off Location
                        </div>
                        <div class="col-span-5 right-text">
                          {{ childItem.drop_off_location || `--` }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Drop off Time</div>
                        <div class="col-span-5 right-text">
                          {{
                            getFormattedDateTime(
                              childItem.drop_off_time,
                              'D MMM, YY hh:mm:ss a'
                            ) || `--`
                          }}
                        </div>
                      </div>

                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Duration</div>
                        <div class="col-span-5 right-text">
                          {{ getHumanReadableDuration(childItem.riding_time) }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Flags</div>
                        <div class="col-span-5 right-text">
                          <template v-if="getFlags(childItem).length > 0">
                            <div class="flex flex-wrap items-center">
                              <template
                                v-for="(flag, flagIndex) in getFlags(childItem)"
                              >
                                <trip-flag-icon
                                  v-tooltip.bottom="
                                    getFlagAlias(flag, childItem)
                                  "
                                  size="sm"
                                  class="mr-1"
                                  :name="flag"
                                  :key="
                                    `trip-${childItemIndex}-flag-${flagIndex}`
                                  "
                                />
                              </template>
                            </div>
                          </template>
                          <template v-else>
                            No Flags
                          </template>
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Status</div>
                        <div class="col-span-5 right-text">
                          <XStatus
                            :text="
                              getUserTripStatus(
                                childItem.status,
                                childItem.status_forced,
                                childItem.status_forced_by_system
                              )
                            "
                            :variant="
                              getUserTripStatusBadge(
                                childItem.status,
                                childItem.status_forced,
                                childItem.status_forced_by_system
                              )
                            "
                            :profile="`trip`"
                          />
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">Actions</div>
                        <div class="col-span-5 right-text">
                          <div
                            v-if="$acl.canUpdate('trips')"
                            class="flex items-center"
                          >
                            <t-dropdown
                              v-if="
                                childItem.status === 'U' ||
                                  childItem.status === 'O' ||
                                  childItem.status === 'R'
                              "
                              variant="smActions"
                            >
                              <template
                                #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                              >
                                <button
                                  class="focus:outline-none"
                                  aria-label="More Actions"
                                  aria-haspopup="true"
                                  @mousedown="mousedownHandler"
                                  @focus="focusHandler"
                                  @blur="blurHandler"
                                  @keydown="keydownHandler"
                                >
                                  <svg
                                    class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    ></path>
                                  </svg>
                                </button>
                              </template>

                              <template #default="{ hide }">
                                <div
                                  @click="hide()"
                                  class="py-1 mt-px bg-white rounded-md shadow-md"
                                >
                                  <t-dropdown-item
                                    @click="
                                      onTripAction({
                                        tripId: childItem.id,
                                        action: 'open',
                                      })
                                    "
                                  >
                                    Force Complete
                                  </t-dropdown-item>
                                  <t-dropdown-item
                                    @click="
                                      onTripAction({
                                        tripId: childItem.id,
                                        action: 'cancel',
                                      })
                                    "
                                    v-if="childItem.status === 'R'"
                                  >
                                    Force Cancel
                                  </t-dropdown-item>
                                </div>
                              </template>
                            </t-dropdown>
                          </div>
                        </div>
                      </div>
                    </td>
                  </FSTableRow>
                </template>
              </template>
            </template>
          </template>
        </FSTable>
      </div>
    </content-section>

    <EdgeStack
      :id="tripAction.esId"
      :busy="tripAction.busy"
      :exit-button="{ text: 'Cancel', hidden: true }"
      :footer-class="{
        fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }"
    >
      <template #header>
        <div class="text-xl font-bold">{{ tripAction.title }}</div>
      </template>

      <template #footer="{ close }">
        <div class="flex items-center gap-5">
          <AppButton text="Cancel" variant="secondary" @click="close()" />

          <AppButton
            text="Confirm"
            :disabled="getClickableStatus"
            :is-loading="tripAction.processing"
            @click="
              onTripAction({ tripId: tripAction.primaryKey, action: 'execute' })
            "
          />
        </div>
      </template>

      <template #default>
        <div class="flex items-center px-3">
          <div class="w-14">
            <div
              class="flex items-center justify-center bg-gray-200 rounded-full w-9 h-9"
            >
              <img
                :src="tripAction.data.user.profile_pic"
                class="object-contain rounded-full w-9 h-9"
              />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                v-text="tripAction.data.user.full_name"
                class="text-base text-black"
              />
              <h6
                v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div
              class="text-right text-blue-600"
              v-text="`TripID: ${$truncate(tripAction.primaryKey, -5)}`"
            />
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/rewind-circle.svg" />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Forcibly lock vehicle`"
              />
              <h6
                v-text="
                  `If you select YES then System will lock the vehicle Forcefully.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_lock ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_lock"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/plus-minus-box.svg" />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Trigger payment for rider`"
              />
              <h6
                v-text="
                  `If you select Yes then Rider will be charged manually.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_payment ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_payment"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div
          v-if="tripAction.models.trigger_payment"
          class="flex justify-end px-2 my-2"
        >
          <div
            class="max-w-md py-4 bg-gray-200 border rounded-md"
            style="min-width: 350px; background: #F3F3F3;"
          >
            <section class="flex items-center justify-between gap-4 px-4">
              <div class="-mt-6">Trip Duration (mins)</div>

              <div class="w-24">
                <AppInput
                  v-model="tripAction.models.riding_time_min"
                  type="number"
                />
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Reservation Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_reservation_fees
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Unlock Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_unlock_fees
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Trip Duration Fee ({{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.default_fees_per_minute
                  }}
                  x {{ tripAction.models.riding_time_min }} mins)
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.trip_amount
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Discount
                </div>

                <div class="w-12 text-oGreen">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.discount_data
                  }}
                </div>
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Subtotal
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">Vat ({{ '0' }}%)</div>

                <div class="w-12">
                  {{ getTripActionCharges.currency_symbol + '0.00' }}
                </div>
              </div>
            </section>

            <section class="pt-3 border-t border-gray-300">
              <div class="flex items-center justify-between px-4">
                <div class="w-full">
                  Total
                </div>

                <div class="w-20 text-3xl text-center">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Reason (Required)`" />
            <AppInput
              type="richselect"
              rules="required"
              name="reason"
              placeholder="Select a reason"
              value-attribute="value"
              text-attribute="text"
              :options="forceCompleteReason"
              hide-search-box
              v-model="tripAction.models.force_complete_reason"
            />
          </div>
        </div>
        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Remarks (Required)`" />
            <AppInput type="textarea" v-model="tripAction.models.remarks" />
          </div>
        </div>
      </template>
    </EdgeStack>
  </section>
</template>

<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { resolveProp, xMan } from '@/utils'
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
import EdgeStack from '@/components/modals/EdgeStack'
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  name: 'TripIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Trips',
    },
    fstId: {
      type: String,
      default: 'trips',
    },
    endpoint: {
      type: String,
      default: useEndpoints.trip.index(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    XStatus: () => import('@/components/badge/XStatus'),
    // MoreActionsDropdown: () =>
    //   import('@/components/dropdown/MoreActionsDropdown'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),
    TripFlagIcon: () => import('@/components/badge/TripFlagIcon'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,

    EdgeStack,
    TDropdownItem,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      indexMetaData: {
        summary: {
          cancelled: 0,
          completed: 0,
          on_trip: 0,
          total: 0,
        },
        count: {
          total: 0,
        },
      },

      tableHeaders: [
        { text: 'Id', width: '5%', sort: 'id' },
        { text: 'Rider', width: '8%', sort: 'user' },
        { text: 'Vehicle QR', width: '8%', sort: 'bike' },
        { text: 'Pickup Time', width: '13%', sort: 'pick_up_time' },
        { text: 'Pickup Location', width: '16%', sort: 'pick_up_location' },
        { text: 'Drop off Location', width: '16%', sort: 'drop_off_location' },
        { text: 'Drop off Time', width: '13%', sort: 'drop_off_time' },
        { text: 'Duration', width: '5%', sort: 'duration' },
        { text: 'Charge', width: '5%', sort: 'cost' },
        { text: 'Flags', width: '16%', sort: null },
        { text: 'Status', width: '5%', sort: 'status' },
        { text: 'Actions', width: '5%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'ID', width: '20%', sort: 'id' },
        { text: 'Vehicle QR', width: '40%', sort: 'bike' },
        { text: 'Charge', width: '30%', sort: 'cost' },
      ],

      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: [],

      tripAction: {
        busy: false,
        esId: 'tripActionES',
        title: 'Force Complete Trip',

        primaryKey: '',
        processing: false, //

        models: {
          trigger_lock: false,
          trigger_payment: false,
          riding_time_min: 0,
          force_complete_reason: '', // in min
          remarks: '',
        },

        data: {
          from_lock_log: false,
          latest_lock_log: {},
          riding_time_mins: 0,

          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            id: '--',
            bike_category: '-',
            currency_symbol: '~',
            vehicle_reservation_fees: 0,
            vehicle_unlock_fees: 0,
            default_fees_per_minute: 0,
            max_billing_per_ride: 0,
            discount_data: 0,
          },
        },
      },
      forceCompleteReason: [
        {
          text: 'Vehicle Locking Issue',
          value: 'VEHICLE_LOCKING_ISSUE',
        },
        {
          text: 'Vehicle Battery Died',
          value: 'VEHICLE_BATTERY_DIED',
        },
        {
          text: 'Vehicle Inactive',
          value: 'VEHICLE_INACTIVE',
        },
        {
          text: 'App Issues',
          value: 'APP_ISSUES',
        },
        {
          text: 'Other',
          value: 'OTHER',
        },
      ],
      opened: [],
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this.$org.default_currency.symbol
    },
    getClickableStatus() {
      if (
        this.tripAction.models.force_complete_reason &&
        this.tripAction.models.remarks
      ) {
        return false
      } else {
        return true
      }
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },

    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: 'invoiced_charges',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },

    getTripActionCharges() {
      let p = this.tripAction.data.pricing_info
      let t = this.tripAction.models.riding_time_min

      p.vehicle_reservation_fees =
        Number(p.vehicle_reservation_fees).toFixed(2) || Number(0).toFixed(2)

      p.vehicle_unlock_fees =
        Number(p.vehicle_unlock_fees).toFixed(2) || Number(0).toFixed(2)

      p.discount_data =
        Number(p.discount_data).toFixed(2) || Number(0).toFixed(2)

      let trip_amount_raw = Number(p.default_fees_per_minute) * Number(t)
      let trip_amount = Number(trip_amount_raw).toFixed(2)

      let total_amount = (
        Number(trip_amount) +
        Number(p.vehicle_reservation_fees) +
        Number(p.vehicle_unlock_fees) -
        Number(p.discount_data)
      ).toFixed(2)

      return { ...p, trip_amount, total_amount }
    },
  },

  async created() {
    await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    getHumanReadableDuration,
    getFormattedDateTime,
    onApplyFilterDateRange,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },

    async generateFilterOptions() {
      let filters = [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { label: 'Completed', value: 'C' },
            { text: 'Force completed By System', value: 'FCS' },
            { text: 'Force Completed by Admin', value: 'FC' },
            { text: 'Reserved', value: 'R' },
            { text: 'Reservation Timed Out', value: 'T' },
            { text: 'Starting Trip', value: 'U' },
            { text: 'On Trip', value: 'O' },
            // { text: 'On Pay', value: 'P' },
            // { text: 'On Feedback', value: 'F' },
            { text: 'Reservation Cancelled', value: 'A' },
            { text: 'Forced Cancelled', value: 'FA' },
          ],
          title: 'Status',
        },
        {
          key: 'area_status',
          type: 'checkbox',
          input: [
            { text: 'Out of Fence', value: 'outside_geofence' },
            { text: 'Service Area', value: 'inside_geofence' },
            {
              text: 'Restricted Area',
              value: 'inside_restricted_area',
            },

            { text: 'Slowzone Area', value: 'inside_slowzone_area' },
          ],
          title: 'Geofence Alert',
        },
        {
          key: 'trip_type',
          type: 'checkbox',
          input: [
            { text: 'Individual', value: 'individual_trips' },
            { text: 'Group', value: 'group_trips' },
            { text: 'Paused', value: 'paused_trips' },
            { text: 'Unpaused', value: 'unpaused_trips' },
          ],
          title: 'Trip Type',
        },

        {
          key: 'feedback',
          type: 'checkbox',
          input: [
            { text: 'Good', value: 'good' },
            { text: 'Bad', value: 'bad' },
          ],
          title: 'Rating',
        },
        {
          type: 'radio',
          key: 'parking_status',
          input: [
            { text: 'Legal', value: 'legally_parked' },
            { text: 'Illegal', value: 'illegally_parked' },
          ],
          title: 'Parking Status',
        },
        {
          type: 'checkbox',
          key: 'discounts',
          input: [
            { text: 'Applied Voucher', value: 'applied_voucher' },
            { text: 'Applied Promo', value: 'applied_promo' },
            { text: 'Applied Pass', value: 'applied_pass' },
          ],
          title: 'Discounts',
        },
        {
          type: 'checkbox',
          key: 'neg_balance',
          input: [
            { text: 'Negative Balance', value: 'rider_neg_balance' },
            { text: 'Trigger Payment', value: 'triggered_payment' },
          ],
          title: 'Balance',
        },
        {
          key: 'tripCost',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.currencySymbol,
            max: 1000,
            minQs: 'min_cost', // query string for min value
            maxQs: 'max_cost', // query string for max value
          },
          title: 'Trip Costs',
        },
        {
          key: 'tripDuration',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: ' Min',
            max: 500,
            minQs: 'min_duration_mins', // query string for min value
            maxQs: 'max_duration_mins', // query string for max value
          },
          title: 'Trip Duration',
        },
      ]

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    getFlagAlias(flag, data = {}) {
      let aliases = {
        was_paused: 'Vehicle was paused',
        legally_parked: 'Vehicle was legally Parked',
        illegally_parked: 'Vehicle was illegally Parked',
        good_rated: 'Good Rating',
        bad_rated: 'Bad Rating',
        inside_service_area: 'Inside Service Area',
        outside_service_area: 'Outside Service Area',
        restricted_area: 'Inside Restricted Area',
        slowzone_area: 'Inside Slowzone Area',
        voucher_applied: 'Voucher Applied',
        promo_applied: 'Promo Applied',
        pass_applied: 'Pass Applied',
        rider_balance_negative: 'Rider Balance Negative',
        trigger_payment_applied: `Triggered ${data.trigger_payment_status &&
          `${this.getTriggerStatus(data.trigger_payment_status)}`}`,
        trigger_payment_failed: `Triggered ${data.trigger_payment_status &&
          `${this.getTriggerStatus(data.trigger_payment_status)}`}`,
      }

      return flag in aliases ? aliases[flag] : '--'
    },
    getTriggerStatus(status) {
      let text = 'Status: '
      if (status === 'I') {
        text = text.concat('Initiated')
      }
      if (status === 'S') {
        text = text.concat('Succeeded')
      }
      if (status === 'IF') {
        text = text.concat('Initiation Failed')
      }
      if (status === 'FF') {
        text = text.concat('Finalization Failed')
      }
      return text
    },
    getFlags(item) {
      let flags = []

      // rating
      if (item.received_good_feedback === true) {
        flags.push('good_rated')
      }

      if (item.received_good_feedback === false) {
        flags.push('bad_rated')
      }

      // parking flag
      if (item.vehicle_was_legally_parked === true) {
        flags.push('legally_parked')
      }
      if (item.vehicle_was_legally_parked === false) {
        flags.push('illegally_parked')
      }

      //geofence alert
      if (item.went_outside_geofence === true) {
        flags.push('outside_service_area')
      }
      if (item.went_outside_geofence === false) {
        flags.push('inside_service_area')
      }
      if (item.went_inside_restricted_area === true) {
        flags.push('restricted_area')
      }
      if (item.went_inside_slowzone_area === true) {
        flags.push('slowzone_area')
      }

      // pause flag
      if (item.was_paused === true) {
        flags.push('was_paused')
      }

      //Offer
      if (item.voucher_applied === true) {
        flags.push('voucher_applied')
      }
      if (item.promo_applied === true) {
        flags.push('promo_applied')
      }
      if (item.pass_applied === true) {
        flags.push('pass_applied')
      }

      //payment
      if (item.rider_balance_negative === true) {
        flags.push('rider_balance_negative')
      }
      if (
        item.rider_balance_negative === true &&
        item.triggered_negative_balance_resolution === true
      ) {
        if (
          item.trigger_payment_status === 'IF' ||
          item.trigger_payment_status === 'FF'
        ) {
          flags.push('trigger_payment_failed')
        } else {
          flags.push('trigger_payment_applied')
        }
      }
      return flags
    },

    async onInvoiceModalReq(id, downloadOnly = false) {
      this.$notify({
        group: 'generic',
        type: 'default',
        title: `Processing`,
        text: 'The action is being executed...',
      })

      await this.$http
        .get(this.$config.trip.api.single(id), {
          params: {
            preferred_currency: resolveProp(
              'fsTable.organizationCurrency.selected',
              this.$store.state
            ),
          },
        })
        .then((res) => {
          this.invoiceModalDetails = res.data

          if (downloadOnly === true) {
            if (this.$refs.invoiceModal) {
              this.$refs.invoiceModal.exportToPDF()
            }
          } else {
            this.$modal.show('invoiceModal')
          }
        })
        .catch((err) => {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    getDirection() {
      var directionsService = new this.google.maps.DirectionsService()
      var directionsDisplay = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })

      var vm = this

      directionsService.route(
        {
          origin: this.getLatLng(this.trip.src_coord),
          destination: this.getLatLng(this.trip.dest_coord),
          travelMode: 'DRIVING',
        },
        function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response)
            var leg = response.routes[0].legs[0]
            vm.makeMarker(leg.start_location, 'start', 'title')
            vm.makeMarker(leg.end_location, 'end', 'title')
          } else {
            vm.makeMarker(response.request.origin.location, 'start', 'title')
            //window.alert('Directions request failed due to ' + status);
          }
        }
      )
    },
    makeMarker(position, icon, title) {
      new this.google.maps.Marker({
        position: position,
        map: this.$refs.map.$mapObject,
        icon:
          icon == 'start'
            ? {
                url: require(`@/assets/img/start_place.png`),
                scaledSize: { width: 63, height: 73 },
              }
            : {
                url: require(`@/assets/img/end_place.png`),
                scaledSize: { width: 63, height: 73 },
              },
        title: title,
      })
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    getDuration(duration) {
      if (!duration) {
        duration = '0 minutes'
      }
      let str = duration.toString()
      return str.replace('minutes', 'm')
    },
    getActionOptions(status) {
      console.log(status)
      return {
        // cancel: "Cancel",
        // end: "End"
      }
      // return status in statuses ? statuses[status] : "-";
    },
    getTooltipText(status, isForced, isForcedBySystem) {
      if (isForced) {
        if (isForcedBySystem) return 'Force Completed by system'
        else return 'Force Completed by Admin'
      } else if (status === 'A') {
        return "'Reservation Cancelled by Rider'"
      } else if (status === 'T') {
        return 'Reservation time out'
      }
    },
    getUserTripStatus(status, isForced, isForcedBySystem) {
      if (isForced && isForcedBySystem) return 'Completed'
      if (isForced && !isForcedBySystem) return 'Force End'
      let statuses = {
        R: 'Reserved',
        A: 'Cancelled',
        U: 'Starting',
        O: 'On Trip',
        V: 'Forced',
        C: 'Completed',
        T: 'Time Out',
      }
      return status in statuses ? statuses[status] : '-'
    },
    getUserTripStatusBadge(status, isForced, isForcedBySystem) {
      if (isForced && isForcedBySystem) return 'ultra-green'
      if (isForced && !isForcedBySystem) return 'red'

      let badges = {
        R: 'blue',
        A: 'red',
        U: 'yellow',
        O: 'purple',
        V: 'red',
        C: 'green',
        T: 'red',
      }
      let s = status in badges ? badges[status] : 'light-blue'

      return s
    },
    view(item) {
      console.log('item = ', item)
      this.$router.push(`/user-management/${item.trip_short_id}`)
    },
    getActionsEndpoint(id) {
      return useEndpoints.trip.update(id)
    },

    async onTripAction({ tripId = null, action = 'open' } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      this.tripAction.primaryKey = tripId
      console.log('onTripAction', tripId, action)

      if (action === 'open') {
        const url = `/dashboard/trips/${tripId}/complete-preload/`

        this.tripAction.busy = true
        this.$edgeStack.open(this.tripAction.esId)
        await this.$http
          .get(url)
          .then((r) => {
            console.log('preLoadRes', r.data)
            this.tripAction.data = r.data
            this.tripAction.models.riding_time_min = r.data.riding_time_mins
          })
          .catch((err) => {
            console.warn('tripActionPreloadErr', err, err.response)
          })
          .finally(() => {
            this.tripAction.busy = false
          })
        return
      }

      if (action === 'close') {
        this.$edgeStack.close(this.tripAction.esId)
        return
      }

      if (action === 'execute') {
        this.tripAction.processing = true
        const url = `/dashboard/trips/${tripId}/complete/`
        const { models } = this.tripAction
        const data = new xMan(models).toFormData()

        await this.$http
          .post(url, data)
          .then((r) => {
            console.log(r.data)
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: `Force Complete Trip`,
              text: r.data?.detail ?? `Successfully completed the action.`,
            })

            this.onTripAction({ action: 'close' })
            this.$store.dispatch('fsTable/fetchData')
          })
          .catch((err) => {
            this.$notify({
              group: 'bottomLeft',
              type: 'danger',
              title: `Failed Action [Code: ${err?.response?.status}]`,
              text:
                err.response?.data?.detail ??
                `Failed to execute force complete.`,
            })
            console.log('tripActionErr', err, err.data)
          })
          .finally(() => {
            this.tripAction.processing = false
          })
      }

      if (action === 'cancel') {
        this.tripAction.processing = true
        const url = `/dashboard/trips/${tripId}/cancel/`

        this.$http
          .post(url)
          .then((res) => {
            console.log(res)
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Trip Reservation Cancellation Success',
                text: `Trip reservation successfully cancelled.`,
              },
              5000
            )

            this.$store.dispatch('fsTable/fetchData')
          })
          .catch((err) => {
            this.$notify(
              {
                group: 'bottomLeft',
                type: 'danger',
                title: `Trip Reservation Cancellation Failure  [Code: ${err?.response?.status}]`,
                text:
                  err.response?.data?.detail ??
                  `Failed to execute trip reservation cancellation.`,
              },
              5000
            )
          })
          .finally(() => {
            this.tripAction.processing = true
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.child-trip-index-badge {
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
  color: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
