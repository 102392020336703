<template lang="">
  <div>
    <RidersListIndex
      :fst-id="`riders-page`"
      :qso="qso"
      :pageTitle="title"
      :endpoint="endpoint"
      :filterItems="filterOptions"
    />
  </div>
</template>
<script>
import RidersListIndex from '@/composites/rider/index/Index.vue'
import { RiderUserConfig } from '@/config/RiderUserConfig'
export default {
  name: 'RiderIndexPage',
  components: {
    RidersListIndex,
  },
  data() {
    return {
      title: 'Riders',
      endpoint: null,
      qso: { append: '', prepend: '' },
      filterOptions: [
        {
          type: 'checkbox',
          key: 'fraud_status',
          input: [
            { text: 'Normal', value: 'N' },
            { text: 'Suspicious', value: 'S' },
            { text: 'Block Listed', value: 'B' },
          ],
          title: 'Fraud Status',
        },
        {
          type: 'checkbox',
          key: 'trip_status',
          input: [
            { text: 'Completed', value: 'N' },
            { text: 'Reservation', value: 'R' },
            { text: 'On Trip', value: 'T' },
            { text: 'On Pay', value: 'P' },
            { text: 'On Feedback', value: 'F' },
          ],
          title: 'Trip Status',
        },
        {
          type: 'radio',
          key: 'user_status',
          input: [
            { text: 'On Hold', value: 'H' },
            { text: 'Verified', value: 'A' },
            { text: 'Unverified', value: 'D' },
          ],
          title: 'Account Status',
        },
        {
          key: 'card_added',
          type: 'toggle',
          input: {
            checkedValue: 'true',
            uncheckedValue: 'false',
            checkedLabel: 'Yes',
            uncheckedLabel: 'No',
          },
          title: 'Card Added',
        },
        {
          key: 'balance',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 9999,
            minQs: 'min_balance', // query string for min value
            maxQs: 'max_balance', // query string for max value
          },
          title: 'Balance',
        },
      ],
    }
  },
  created() {
    this.generateEndPoint()
  },
  methods: {
    generateEndPoint() {
      this.endpoint =
        RiderUserConfig.api.index + `?fleet=${this.$route.params.id}&`
    },
  },
}
</script>
<style lang=""></style>
